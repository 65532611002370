import styled from "@emotion/styled"
import OnlyFans from "../../../assets/Icons/FullColor/OnlyFans.svg"
import OnlyFansBlack from "../../../assets/Icons/Black/OnlyFans.svg"
import OnlyFansInverted from "../../../assets/Icons/White/OnlyFans.svg"
import Instagram from "../../../assets/Icons/FullColor/Insta.svg"
import InstagranInverted from "../../../assets/Icons/White/Instagram.svg"
import InstagramBlack from "../../../assets/Icons/Black/Instagram.svg"
import Twitter from "../../../assets/Icons/FullColor/Twitter.svg"
import TwitterInverted from "../../../assets/Icons/White/Twitter.svg"
import TwitterBlack from "../../../assets/Icons/Black/Twitter.svg"
import TikTok from "../../../assets/Icons/FullColor/TikTok.svg"
import TikTokInverted from "../../../assets/Icons/White/TikTok.svg"
import TikTokBlack from "../../../assets/Icons/Black/TikTok.svg"
import Reddit from "../../../assets/Icons/FullColor/Reddit.svg"
import RedditBlack from "../../../assets/Icons/Black/Reddit.svg"
import Bundles from "../../../assets/Icons/FullColor/Bundle.svg"
import Facebook from "../../../assets/SocialMediaAccounts/Facebook.svg"
import FacebookBlack from "../../../assets/Icons/Black/Facebook.svg"
import SnapChat from "../../../assets/Icons/FullColor/SnapChat.svg"
import SnapChatBlack from "../../../assets/Icons/Black/SnapChat.svg"
import YouTube from "../../../assets/SocialMediaAccounts/Youtube.svg"
import YoutubeInverted from "../../../assets/SocialMediaAccounts/YoutubeInverted.svg"
import YouTubeBlack from "../../../assets/Icons/Black/Youtube.svg"
import Fansly from "../../../assets/SocialMediaAccounts/Fansly.svg"
import FanslyInverted from "../../../assets/SocialMediaAccounts/FanslyInverted.svg"
import FanslyBlack from "../../../assets/Icons/Black/Fansly.svg"
import FacebookInverted from "../../../assets/SocialMediaAccounts/FacebookInverted.svg"
import LinkTree from "../../../assets/SocialMediaAccounts/LinkTree.svg"
import LinkTreeBlack from "../../../assets/Icons/Black/Linktree.svg"
import ServiceProvider from "../../../assets/Icons/FullColor/ServiceProvider.svg"
import Bsky from "../../../assets/Icons/FullColor/Bsky.svg"
import BskyBlack from "../../../assets/Icons/Black/Bsky.svg"
import BskyInverted from "../../../assets/Icons/White/Bsky.svg"
import ManyVids from "../../../assets/Icons/FullColor/ManyVids.svg"
import ManyVidsBlack from "../../../assets/Icons/Black/ManyVids.svg"
import ManyVidsInverted from "../../../assets/Icons/White/ManyVids.svg"

import { Colors } from "../../../../styles/Colors"
import { useTheme } from "@mui/material"

export type Brands =
  | "OnlyFans"
  | "Facebook"
  | "Instagram"
  | "X/Twitter"
  | "TikTok"
  | "Reddit"
  | "SnapChat"
  | "Bundles"
  | "Youtube"
  | "Fansly"
  | "Linktree"
  | "ManyVids"
  | "Service Provider"
  | "Bluesky"

export const parseBrandFromString = (brand: string): Brands => {
  switch (brand.toLowerCase()) {
    case "":
    case "onlyfans":
      return "OnlyFans"
    case "instagram":
      return "Instagram"
    case "x/twitter":
      return "X/Twitter"
    case "tiktok":
      return "TikTok"
    case "reddit":
      return "Reddit"
    case "facebook":
      return "Facebook"
    case "snapchat":
      return "SnapChat"
    case "youtube":
      return "Youtube"
    case "fansly":
      return "Fansly"
    case "linktree":
      return "Linktree"
    case "manyvids":
      return "ManyVids"
    case "bundles":
      return "Bundles"
    case "service provider":
      return "Service Provider"
    case "bluesky":
      return "Bluesky"
    default:
      console.warn(`Unknown Brand: ${brand}`)
      return "OnlyFans"
  }
}

export interface ILogoProps {
  logoType: Brands
  inverted?: boolean
  classes?: string[]
  size?: "Large" | "Medium" | "Small"
}

export function getBrandDisplayName(brand: Brands): string {
  switch (brand) {
    case "SnapChat":
      return "Snap Chat"
    default:
      return brand
  }
}

// eslint-disable-next-line complexity
export function getLogo(brand: string, inverted: boolean): string {
  switch (brand.toLowerCase()) {
    case "onlyfans":
      return inverted ? OnlyFansInverted.src : OnlyFans.src
    case "instagram":
      return inverted ? InstagranInverted.src : Instagram.src
    case "x/twitter":
      return inverted ? TwitterInverted.src : Twitter.src
    case "tiktok":
      return inverted ? TikTokInverted.src : TikTok.src
    case "reddit":
      return Reddit.src
    case "facebook":
      return inverted ? FacebookInverted.src : Facebook.src
    case "snapchat":
      return SnapChat.src
    case "youtube":
      return inverted ? YoutubeInverted.src : YouTube.src
    case "fansly":
      return inverted ? FanslyInverted.src : Fansly.src
    case "linktree":
      return LinkTree.src
    case "manyvids":
      return inverted ? ManyVidsInverted.src : ManyVids.src
    case "bundles":
      return Bundles.src
    case "service provider":
      return ServiceProvider.src
    case "bluesky":
      return inverted ? BskyInverted.src : Bsky.src
  }
  return ""
}

export function getLogoBlack(brand: string): string {
  switch (brand.toLowerCase()) {
    case "onlyfans":
      return OnlyFansBlack.src
    case "instagram":
      return InstagramBlack.src
    case "x/twitter":
      return TwitterBlack.src
    case "tiktok":
      return TikTokBlack.src
    case "reddit":
      return RedditBlack.src
    case "facebook":
      return FacebookBlack.src
    case "snapchat":
      return SnapChatBlack.src
    case "youtube":
      return YouTubeBlack.src
    case "fansly":
      return FanslyBlack.src
    case "linktree":
      return LinkTreeBlack.src
    case "manyvids":
      return ManyVidsBlack.src
    case "service provider":
      return ServiceProvider.src
    case "bluesky":
      return BskyBlack.src
    case "bundles":
  }
  return ""
}

export function getBrandGradientAndIcon(brand: string): {
  icon: string
  background: string
  textColor: string
} {
  const theme = useTheme()
  switch (brand.toLowerCase()) {
    case "onlyfans":
      return {
        icon: getLogo(brand, true),
        background: Colors.brandHeaderColors.OnlyFans,
        textColor: Colors.white,
      }
    case "instagram":
      return {
        icon: getLogo(brand, true),
        background: Colors.brandHeaderColors.Instagram,
        textColor: Colors.white,
      }
    case "x/twitter":
      return {
        icon: getLogo(brand, true),
        background: Colors.brandHeaderColors.Twitter,
        textColor: Colors.white,
      }
    case "tiktok":
      return {
        icon: getLogo(brand, false),
        background: Colors.brandHeaderColors.TikTok,
        textColor: Colors.white,
      }
    case "reddit":
      return {
        icon: getLogo(brand, false),
        background: Colors.brandHeaderColors.Reddit,
        textColor: Colors.white,
      }
    case "bundles":
      return {
        icon: getLogo(brand, true),
        background: Colors.brandHeaderColors.Bundles,
        textColor: theme.palette.text.primary,
      }
    case "youtube":
      return {
        icon: getLogo(brand, true),
        background: Colors.brandHeaderColors.Youtube,
        textColor: Colors.white,
      }
    case "fansly":
      return {
        icon: getLogo(brand, true),
        background: Colors.brandHeaderColors.Fansly,
        textColor: Colors.white,
      }
    case "snapchat":
      return {
        icon: getLogo(brand, false),
        background: Colors.brandHeaderColors.SnapChat,
        textColor: theme.palette.text.primary,
      }
    case "facebook":
      return {
        icon: getLogo(brand, true),
        background: Colors.brandHeaderColors.Facebook,
        textColor: Colors.white,
      }
    case "service provider":
      return {
        icon: getLogo(brand, true),
        background: Colors.brandHeaderColors.ServiceProvider,
        textColor: Colors.white,
      }
    case "bluesky":
      return {
        icon: getLogo(brand, true),
        background: Colors.brandHeaderColors.Bsky,
        textColor: Colors.white,
      }
    case "manyvids":
      return {
        icon: getLogo(brand, true),
        background: Colors.brandHeaderColors.ManyVids,
        textColor: Colors.white,
      }
    default:
      return {
        icon: getLogo(brand, false),
        background: Colors.white,
        textColor: theme.palette.text.primary,
      }
  }
}

export const Logo = ({
  logoType,
  inverted = false,
  classes = [],
  size = "Large",
}: ILogoProps): JSX.Element => {
  const logo = getLogo(logoType, inverted)
  return <StyledLogo logoSize={size} className={classes.join(" ")} src={logo} />
}

const StyledLogo = styled.img<{ logoSize: string }>`
  width: ${(props) =>
    props.logoSize === "Large"
      ? "32px"
      : props.logoSize === "Medium"
      ? "25px"
      : "16px"};
  height: ${(props) =>
    props.logoSize === "Large"
      ? "32px"
      : props.logoSize === "Medium"
      ? "25px"
      : "16px"};
`
