import { useContext, useEffect, useMemo, useState } from "react"
import {
  Divider,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import styled from "@emotion/styled"
import { ActionTypes, UserContext } from "../../contexts/user"
import MobileNavbarIcon from "../assets/BlossmLogoSmall.svg"
import DesktopNavarIcon from "../assets/BlossmLogoLarge.svg"
import Link from "next/link"
import { logout } from "../../utils/requests"
import { ErrorDialog } from "./ErrorDialog"
import {
  SiteNotificationsContext,
  ActionTypes as SiteNotificationActionTypes,
} from "../../contexts/siteNotificationContext"
import Badge from "@mui/material/Badge"
import {
  AddBoxOutlined,
  AlternateEmail,
  AttachMoney,
  ExploreOutlined,
  HelpOutline,
  Logout,
  NotificationsOutlined,
  Person,
  SettingsOutlined,
  Menu,
  MailOutlined,
  Check,
  Inventory2Outlined,
  ArrowDropDown,
  Star,
  RocketLaunchOutlined,
} from "@mui/icons-material"
import { Colors } from "../../styles/Colors"
import { useRouter } from "next/router"
import { ModalLogout } from "../Molecules/ModalLogout/ModalLogout"
import { Avatar } from "../Atoms/Avatar"
import { TabletPopOutMenu } from "../Atoms/TabletPopOutMenu"
import instagram from "../assets/Icons/Black/Instagram.svg"
import twitter from "../assets/Icons/Black/Twitter.svg"
import tiktok from "../assets/Icons/Black/TikTok.svg"
import telegram from "../assets/Icons/Black/Telegram.svg"
import { SelectProfileRadioButtons } from "./SelectProfileRadioButtons"
import { PopUp } from "../Atoms/PopUp"
import { SiteActionRequiredContext } from "../../contexts/siteActionRequiredContext"
import { MessageContext } from "../../contexts/websocket"
import { BalanceContext } from "../../contexts/balance"
import {
  WafflesContext,
  ActionTypes as WaffleActionTypes,
  initialState as waffleInitialState,
} from "../../contexts/waffles"
import { BlossmImage } from "../Atoms/BlossmImage"

// eslint-disable-next-line complexity
export const DesktopNavbar = (): JSX.Element => {
  const { dispatch, context } = useContext(UserContext)
  const { balanceContext } = useContext(BalanceContext)
  const { siteNotificationsContext, siteNotificationsDispatch } = useContext(
    SiteNotificationsContext
  )
  const theme = useTheme()
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false)
  const [errorTitle, setErrorTitle] = useState<string>("")
  const [loggedIn, setLoggedIn] = useState<boolean>(false)
  const router = useRouter()
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [logoutOpen, setLogoutOpen] = useState(false)
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"))
  const [tabletMenuExpanded, setTabletMenuExpanded] = useState(false)
  const [openProfileSelection, setOpenProfileSelection] = useState(false)
  const { siteActionRequiredContext } = useContext(SiteActionRequiredContext)
  const { messageContext } = useContext(MessageContext)
  const { wafflesContext, wafflesDispatch } = useContext(WafflesContext)

  useEffect(() => {
    setTabletMenuExpanded(false)
  }, [router.pathname])

  const createUrl = context.userIdVerified
    ? "/create"
    : "/id-verification/verify?source=navclick"

  const logOutModal = (): void => {
    logout()
      .then(() => {
        dispatch({
          type: ActionTypes.Logout,
          payload: {
            isAuthenticated: false,
          },
        })
        siteNotificationsDispatch({
          type: SiteNotificationActionTypes.ResetSiteNotification,
          payload: {
            siteNotification: null,
          },
        })
        wafflesDispatch({
          type: WaffleActionTypes.DropWaffles,
          payload: waffleInitialState,
        })
        setLogoutOpen(false)
        router.push("/")
      })
      .catch(() => {
        setLogoutOpen(false)
        setErrorDialogOpen(true)
        setErrorTitle("Failed to log out")
        setErrorMessages(["Failed to log out. Please try again."])
      })
  }

  useMemo(() => {
    if (context.username !== "") {
      setLoggedIn(true)
    } else {
      setLoggedIn(false)
    }
  }, [context.username])

  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"))

  return (
    <StyledDiv theme={theme}>
      <ModalLogout
        open={logoutOpen}
        title="Log Out?"
        text="Are you sure you want to log out?"
        onHandle={logOutModal}
        onClose={() => setLogoutOpen(false)}
      />
      {!isDesktop && (
        <div
          className={"tablet-menu-expander"}
          onClick={() => setTabletMenuExpanded(true)}
        >
          <Menu sx={{ color: theme.palette.text.secondary }} />
        </div>
      )}
      <Link href={"/"}>
        {isDesktop ? (
          <img src={DesktopNavarIcon.src} className={"desktop-navbar-icon"} />
        ) : (
          <div className={"navbar-icon-outer-container"}>
            <div className={"navbar-icon-container"}>
              <BlossmImage
                alt={"nav-icon"}
                src={MobileNavbarIcon.src}
                layout={"fill"}
              />
            </div>
          </div>
        )}
      </Link>
      <div className={"desktop-nav-body"}>
        <Link href={"/"}>
          <StyledNavItem
            theme={theme}
            selected={router.pathname === "/marketplace"}
          >
            <Tooltip title={!isDesktop && "Marketplace"}>
              <ExploreOutlined sx={{ color: "inherit" }} />
            </Tooltip>
            {isDesktop && (
              <Typography color={"inherit"} variant={"body1"}>
                Marketplace
              </Typography>
            )}
          </StyledNavItem>
        </Link>
        {loggedIn ? (
          <>
            <Link href={"/notifications"}>
              <StyledNavItem
                theme={theme}
                selected={router.pathname === "/notifications"}
              >
                <Badge
                  badgeContent={
                    siteNotificationsContext.newSiteNotificationCount
                  }
                  color="primary"
                >
                  <Tooltip title={!isDesktop && "Notifications"}>
                    <NotificationsOutlined sx={{ color: "inherit" }} />
                  </Tooltip>
                </Badge>
                {isDesktop && (
                  <Typography color={"inherit"} variant={"body1"}>
                    Notifications
                  </Typography>
                )}
              </StyledNavItem>
            </Link>
            <Link href={"/orders"}>
              <StyledNavItem
                theme={theme}
                selected={router.pathname === "/orders"}
              >
                <Badge
                  badgeContent={
                    siteActionRequiredContext.actionRequiredNotifications
                      ? siteActionRequiredContext.actionRequiredNotifications
                      : 0
                  }
                  color="primary"
                >
                  <Tooltip title={!isDesktop && "My Orders"}>
                    <Inventory2Outlined sx={{ color: "inherit" }} />
                  </Tooltip>
                </Badge>
                {isDesktop && (
                  <Typography color={"inherit"} variant={"body1"}>
                    My Orders
                  </Typography>
                )}
              </StyledNavItem>
            </Link>
            {wafflesContext.type !== "" &&
              wafflesContext.flags.indexOf("show_traffic_coming_soon") > -1 && ( // Waffle Check
                <Link href={"/traffic"}>
                  <StyledNavItem
                    theme={theme}
                    selected={router.pathname === "/traffic"}
                  >
                    <Tooltip title={!isDesktop && "Buy Traffic"}>
                      <RocketLaunchOutlined sx={{ color: "inherit" }} />
                    </Tooltip>
                    {isDesktop && (
                      <Typography color={"inherit"} variant={"body1"}>
                        Buy Traffic
                      </Typography>
                    )}
                  </StyledNavItem>
                </Link>
              )}
          </>
        ) : (
          <Link href={"/login"}>
            <StyledNavItem theme={theme}>
              <Tooltip title={!isDesktop && "Log In"}>
                <Person sx={{ color: "inherit" }} />
              </Tooltip>
              {isDesktop && (
                <Typography color={"inherit"} variant={"body1"}>
                  Log In
                </Typography>
              )}
            </StyledNavItem>
          </Link>
        )}
        <Divider sx={{ width: "100%" }} />
        {loggedIn && (
          <>
            <StyledNavItem
              theme={theme}
              selected={
                router.pathname === "/[name]" &&
                router.query.name === context.profileName
              }
            >
              <Link href={"/" + context.profileName}>
                <Tooltip title={!isDesktop && "Profile"}>
                  <Avatar
                    online={false}
                    profileImage={context.avatarUrl}
                    size={"Small"}
                  />
                </Tooltip>
              </Link>
              {isDesktop && (
                <StyledSwitchProfileItem title={context.profileName}>
                  <Link href={"/" + context.profileName}>
                    <Typography
                      noWrap
                      color={"inherit"}
                      variant={"body1"}
                      sx={{ width: "100%" }}
                    >
                      @{context.profileName}
                    </Typography>
                  </Link>
                  <ArrowDropDown
                    onClick={() => setOpenProfileSelection(true)}
                    sx={{ cursor: "pointer", margin: "0px 8px" }}
                  />
                </StyledSwitchProfileItem>
              )}
            </StyledNavItem>
            {!context.userIdVerified && (
              <Link href={"/id-verification/verify?source=navclick"}>
                <StyledNavItem
                  theme={theme}
                  selected={router.pathname === "/id-verification/verify"}
                >
                  <Tooltip title={!isDesktop && "Verify ID"}>
                    <div className={"verify-check-background"}>
                      <Check sx={{ color: Colors.white }} fontSize={"small"} />
                    </div>
                  </Tooltip>
                  {isDesktop && (
                    <>
                      <Typography color={"inherit"} variant={"body1"}>
                        Verify ID
                      </Typography>
                      <div className={"verify-dot"} />
                    </>
                  )}
                </StyledNavItem>
              </Link>
            )}
            <Link href={createUrl}>
              <StyledNavItem
                theme={theme}
                selected={router.pathname === "/create"}
              >
                <Tooltip title={!isDesktop && "Create"}>
                  <AddBoxOutlined sx={{ color: "inherit" }} />
                </Tooltip>
                {isDesktop && (
                  <Typography color={"inherit"} variant={"body1"}>
                    Create
                  </Typography>
                )}
              </StyledNavItem>
            </Link>
            <Link href={`/add-accounts?profileName=${context.profileName}`}>
              <StyledNavItem
                theme={theme}
                selected={router.pathname === "/add-accounts"}
              >
                <Tooltip title={!isDesktop && "Socials"}>
                  <AlternateEmail sx={{ color: "inherit" }} />
                </Tooltip>
                {isDesktop && (
                  <Typography color={"inherit"} variant={"body1"}>
                    Socials
                  </Typography>
                )}
              </StyledNavItem>
            </Link>
            <Link href={"/payout"}>
              <StyledNavItem
                theme={theme}
                selected={router.pathname === "/payout"}
              >
                <Tooltip title={!isDesktop && "Balance"}>
                  <AttachMoney sx={{ color: "inherit" }} />
                </Tooltip>
                {isDesktop && (
                  <div>
                    <Typography color={"inherit"} variant={"body1"}>
                      Balance
                    </Typography>
                    <Typography color={"inherit"} variant={"body2"}>
                      $
                      {balanceContext.balance &&
                        balanceContext.balance >= 0 &&
                        balanceContext.balance.toFixed(2)}
                    </Typography>
                  </div>
                )}
              </StyledNavItem>
            </Link>
            <Link href={"/settings/account"}>
              <StyledNavItem
                theme={theme}
                selected={router.pathname === "/settings/account"}
              >
                <Tooltip title={!isDesktop && "Settings"}>
                  <SettingsOutlined sx={{ color: "inherit" }} />
                </Tooltip>
                {isDesktop && (
                  <Typography color={"inherit"} variant={"body1"}>
                    Settings
                  </Typography>
                )}
              </StyledNavItem>
            </Link>
            {isTablet && (
              <Link href={"/messages"}>
                <StyledNavItem
                  theme={theme}
                  selected={router.pathname === "/messages"}
                >
                  <Tooltip title={!isDesktop && "Messages"}>
                    <Badge
                      badgeContent={messageContext.unreadMessageCount}
                      color="primary"
                    >
                      <MailOutlined sx={{ color: "inherit" }} />
                    </Badge>
                  </Tooltip>
                </StyledNavItem>
              </Link>
            )}
          </>
        )}
        <a target="_blank" href={"https://blossm.zendesk.com/hc/en-us/"}>
          <StyledNavItem theme={theme}>
            <Tooltip title={!isDesktop && "Need Help?"}>
              <HelpOutline sx={{ color: "inherit" }} />
            </Tooltip>
            {isDesktop && (
              <Typography color={"inherit"} variant={"body1"}>
                Need Help?
              </Typography>
            )}
          </StyledNavItem>
        </a>
        {loggedIn && (
          <Link href={"/refer/" + context.profileName}>
            <StyledNavItem
              theme={theme}
              selected={router.pathname === "/refer/[name]"}
            >
              <Tooltip title={!isDesktop && "Refer and Earn"}>
                <Star sx={{ color: "inherit" }} />
              </Tooltip>
              {isDesktop && (
                <Typography
                  color={theme.palette.text.secondary}
                  variant={"body1"}
                >
                  Refer and Earn
                </Typography>
              )}
            </StyledNavItem>
          </Link>
        )}
        {loggedIn && (
          <>
            <StyledNavItem theme={theme} onClick={() => setLogoutOpen(true)}>
              <Tooltip title={!isDesktop && "Log Out"}>
                <Logout sx={{ color: "inherit" }} />
              </Tooltip>
              {isDesktop && (
                <Typography
                  color={theme.palette.text.secondary}
                  variant={"body1"}
                >
                  Log Out
                </Typography>
              )}
            </StyledNavItem>
          </>
        )}
        {isDesktop && (
          <InfoLinks>
            <Divider sx={{ width: "100%" }} />
            <Link
              href={"/terms"}
              passHref
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography
                variant={"body2"}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Terms of Service
              </Typography>
            </Link>
            <Link
              href={"/privacy"}
              passHref
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography
                variant={"body2"}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Privacy
              </Typography>
            </Link>
            <Link
              href={"/guidelines"}
              passHref
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography
                variant={"body2"}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Guidelines
              </Typography>
            </Link>
            {/*<Link href={"/billing"}>*/}
            {/*    <Typography variant={"body2"} sx={{cursor: "pointer", "&:hover": {*/}
            {/*            textDecoration: "underline",*/}
            {/*        }}}>Billing</Typography>*/}
            {/*</Link>*/}
            <a
              href={
                "https://blossm.zendesk.com/hc/en-us/requests/new?ticket_form_id=12510615290637"
              }
              target="_blank"
            >
              <Typography
                variant={"body2"}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Feedback
              </Typography>
            </a>
            <a href={"https://blossmblog.com/"} target="_blank">
              <Typography
                variant={"body2"}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Blossm Blog
              </Typography>
            </a>
            <Link
              href={"/media-kit"}
              passHref
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography
                variant={"body2"}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Media Kit
              </Typography>
            </Link>
            <ContactBox>
              <a href={"https://www.instagram.com/blossmhq/"} target="_blank">
                <img src={instagram.src} width={24} height={24} />
              </a>
              <a href={"https://twitter.com/blossmhq/"} target="_blank">
                <img src={twitter.src} width={24} height={24} />
              </a>
              <a href={"https://www.tiktok.com/@blossmhq"} target="_blank">
                <img src={tiktok.src} width={24} height={24} />
              </a>
              <a href={"https://t.me/blossmhq"} target="_blank">
                <img src={telegram.src} width={24} height={24} />
              </a>
            </ContactBox>
          </InfoLinks>
        )}
      </div>
      <PopUp open={openProfileSelection} handleToggle={setOpenProfileSelection}>
        <SelectProfileRadioButtons setDrawerOpen={setOpenProfileSelection} />
      </PopUp>
      <ErrorDialog
        errorTitle={errorTitle}
        errorDialogOpen={errorDialogOpen}
        setErrorDialogOpen={setErrorDialogOpen}
      >
        {errorMessages.map((error: string) => {
          return <div>{error}</div>
        })}
      </ErrorDialog>
      {tabletMenuExpanded && (
        <TabletPopOutMenu handleClose={() => setTabletMenuExpanded(false)} />
      )}
    </StyledDiv>
  )
}

const InfoLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 16px;
`

export const ContactBox = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 16px;
`

const StyledNavItem = styled.div<{ theme: Theme; selected?: boolean }>`
  display: flex;
  gap: 5px;
  background: ${(props) =>
    props.selected ? Colors.blues.shadyBlue : Colors.white};
  ${(props) =>
    props.selected &&
    `
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 2px;
    padding-right: 2px;
  `}
  color: ${(props) =>
    props.selected
      ? props.theme.palette.primary.main
      : props.theme.palette.text.secondary};
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  transition: padding 0.3s;
  text-overflow: ellipsis;

  :hover {
    background: ${Colors.grays.lighter};
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .verify-check-background {
    display: flex;
    width: 24px;
    height: 24px;
    background: ${(props) => props.theme.palette.info.light};
    border-radius: 50%;
    align-items: center;
    justify-content: center;
  }

  .verify-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${(props) => props.theme.palette.warning.light};
    margin-left: auto;
    margin-right: 16px;
  }
`

const StyledDiv = styled.div<{ theme: Theme }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  padding-top: 24px;
  align-items: center;
  margin: auto;
  overflow-x: hidden;

  ${(props) => props.theme.breakpoints.up("md")} {
    align-items: flex-start;
    margin: 0;
  }

  .navbar-icon-container {
    margin-bottom: 10px;
    min-width: 40px;
    min-height: 40px;

    ${(props) => props.theme.breakpoints.down("lg")} {
      min-width: 30px;
      min-height: 30px;
      margin-left: 10px;
    }

    overflow: hidden;
    position: relative;
  }

  .desktop-nav-body {
    display: flex;
    flex-direction: column;
    padding: 8px;
    width: 100%;
    gap: 24px;
    height: 100%;

    ${(props) => props.theme.breakpoints.down("lg")} {
      align-items: center;
    }

    ${(props) => props.theme.breakpoints.down("xl")} {
      padding: 4px;
    }

    .desktop-nav-row {
      display: flex;
      gap: 20px;
      color: ${(props) => props.theme.palette.text.secondary};
      align-items: center;
      cursor: pointer;
      border-radius: 5px;
      transition: padding 0.3s;

      :hover {
        background: ${Colors.grays.lighter};
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }
  }

  .navbar-icon-outer-container {
    width: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;

    .mobile-navbar-icon {
      width: 53px;
      height: 53px;
      margin-left: 8px;

      ${(props) => props.theme.breakpoints.down("lg")} {
        width: 40px;
        height: 40px;
      }

      ${(props) => props.theme.breakpoints.down("md")} {
        width: 30px;
        height: 30px;
      }
    }
  }

  .desktop-navbar-icon {
    width: 152px;
    height: 36px;
    margin-bottom: 24px;
    padding-left: 8px;
    cursor: pointer;
  }

  .tablet-menu-expander {
    cursor: pointer;
    margin-bottom: 16px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`
const StyledSwitchProfileItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  justify-content: space-between;
`
