export const Colors = {
  // NOTE DO NOT PUT THEME COLORS IN HERE, THIS IS ONLY FOR COLORS OUTSIDE OF THE THEME
  background: {
    primary: "#FAFAFA",
    shaded: "rgba(50, 113, 208, 0.05)",
  },
  grays: {
    disabled: "#565656",
    lighter: "rgba(0, 0, 0, 0.08)",
    light: "rgba(246, 246, 246, 1)",
    medium: "rgba(189, 189, 189, 1)",
    modalBacking: "rgba(0, 0, 0, .5)",
    chatMessage: "#eeedee",
  },
  greens: {
    light: "#02AB69",
    dark: "rgba(0, 114, 69, 1)",
    candy: "rgba(129, 255, 206, 1)",
    online: "#66BB6A",
  },
  blues: {
    chatMessage: "#e8f2f9",
    light: "#00AEEF",
    medium: "#448AFF",
    dark: "rgba(68, 138, 255, 1)",
    OnlyFans: "rgba(0, 174, 239, 1)",
    facebook: "rgba(24, 119, 242, 1)",
    twitter: "rgba(29, 161, 242, 1)",
    shadyBlue: "rgba(50, 113, 208, 0.08)",
  },
  pink: {
    light: "rgb(255, 215, 212)",
    vibrant: "#FF4081",
  },
  shade: {
    light: "rgba(0, 0, 0, .4)",
    shadient:
      "linear-gradient(0deg, rgba(0,0,0,1), rgba(0,0,0,0.77), rgba(0,0,0,0))",
  },
  white: "rgba(255, 255, 255, 1)",
  black: "rgba(0, 0, 0, 1)",
  nav: {
    primary: "rgba(249, 78, 211, 1)",
  },
  teal: "rgba(0, 137, 123, 1)",
  transparent: "rgba(0,0,0,0)",
  blueGray: {
    light: "rgba(55, 71, 79, 1)",
    dark: "rgba(38, 50, 56, 1)",
  },
  action: {
    active: {
      light: "rgba(0, 0, 0, 0.54)",
      dark: "rgba(255, 255, 255, 0.56)",
    },
  },
  ratings: {
    active: {
      light: "rgba(255, 180, 0, 1)",
      dark: "rgba(43, 43, 43, 1)",
    },
  },
  brandHeaderColors: {
    OnlyFans: "linear-gradient(265.11deg, #00AEEF 40.62%, #CCEFFC 144.32%)",
    Facebook: "#1877F2",
    ServiceProvider: "#5D54DB",
    Instagram:
      "linear-gradient(84.38deg, #FFC351 -25.88%, #F8514E 31.41%, #505CCB 78.88%)",
    Twitter: "Black",
    TikTok: "#000000",
    Reddit: "#FF3F18",
    SnapChat: "rgba(255, 252, 0, 1)",
    Bundles: "rgba(255, 255, 255, 1)",
    Youtube: "#FF0000",
    Fansly: "#2699F7",
    Bsky: "linear-gradient(93deg, #1F85FF 31.98%, #1D2227 159.9%);",
    ManyVids: "linear-gradient(86.68deg, #9A4FED 35.32%, #5553E5 97.91%)",
  },
}
